<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Customer
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="Full Name *"
            v-model="fields.full_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('full_name')"
            :error-messages="errors['full_name']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
          <v-text-field
            label="Address Line 1 *"
            v-model="fields.address_line_1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_line_1')"
            :error-messages="errors['address_line_1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.address_line_2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_line_2')"
            :error-messages="errors['address_line_2']"
          ></v-text-field>
          <v-text-field
            label="Address Company"
            v-model="fields.address_company"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_company')"
            :error-messages="errors['address_company']"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.address_city"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_city')"
            :error-messages="errors['address_city']"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.address_postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_postcode')"
            :error-messages="errors['address_postcode']"
          ></v-text-field>
          <v-text-field
            label="County"
            v-model="fields.address_county"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_county')"
            :error-messages="errors['address_county']"
          ></v-text-field>
          <v-autocomplete
            label="Country *"
            v-model="fields.address_country"
            :items="availableCountries"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address_country')"
            :error-messages="errors['address_country']"
          ></v-autocomplete>

          <v-switch
            label="Gift Aid"
            v-model="fields.gift_aid"
            inset
            :error="errors.hasOwnProperty('gift_aid')"
            :error-messages="errors['gift_aid']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      errors: {},
      fields: {
        full_name: null,
        email: null,
        phone: null,
        address_line_1: null,
        address_line_2: null,
        address_company: null,
        address_city: null,
        address_postcode: null,
        address_county: null,
        address_country: "United Kingdom",
        gift_aid: false,
      },
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(customer = null) {
      if (customer !== null) {
        this.customer = customer;
        this.isEditing = true;
        this.fields.full_name = this.customer.full_name;
        this.fields.email = this.customer.email;
        this.fields.phone = this.customer.phone;
        this.fields.address_line_1 = this.customer.address_line_1;
        this.fields.address_line_2 = this.customer.address_line_2;
        this.fields.address_company = this.customer.address_company;
        this.fields.address_city = this.customer.address_city;
        this.fields.address_postcode = this.customer.address_postcode;
        this.fields.address_county = this.customer.address_county;
        this.fields.address_country = this.customer.address_country;

        this.fields.gift_aid = this.customer.gift_aid;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.customerId = this.customer.id;
      }

      this.$store
        .dispatch("craigtoun/customersStore/saveCustomer", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.customer = {};
      this.fields.full_name = null;
      this.fields.email = null;
      this.fields.phone = null;
      this.fields.address_line_1 = null;
      this.fields.address_line_2 = null;
      this.fields.address_company = null;
      this.fields.address_city = null;
      this.fields.address_postcode = null;
      this.fields.address_county = null;
      this.fields.gift_aid = false;
      this.fields.address_country = "United Kingdom";
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
